/* styles.css */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
body {
	margin: 0;
	padding: 0;
	overflow-x: hidden;
	@apply dark:bg-black dark:text-white;
}
.container {
	max-width: 100%;
	overflow: hidden;
}
